<template>
  <BaseList :list-query="places" route="place" locale-section="pages.places" />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "PlaceList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      places: gql`
        query places {
          list: places {
            id
            name: place
            place
            parishes {
              current
            }
          }
        }
      `
    };
  }
};
</script>
